import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import "./App.css";

import Feed from "./components/news/Feed";
import NftCollection from "./components/portfolio/nfts/NftCollection";
import Sidebar from "./components/sidebar/Sidebar";
import { CardanoWalletProvider } from "./context/AuthenticationContextProvider";

import Home from "./pages/Home";
import Portfolio from "./pages/Portfolio";

function App() {
  return (
    <CardanoWalletProvider>
      <Router>
        <div className="min-h-screen flex">
          <Sidebar />
          <main className="flex flex-1 min-h-screen overflow-y-hidden">
            <Routes>
              <Route path="/" element={<Feed uri="all" />} />
              <Route path="/news/all" element={<Feed uri="all" />} />
              <Route path="/news/discord" element={<Feed uri="discord" />} />
              <Route path="/news/twitter" element={<Feed uri="twitter" />} />
              <Route path="/news/youtube" element={<Feed uri="youtube" />} />
              <Route path="/news/blog" element={<Feed uri="blog" />} />
              <Route
                path="/portfolio/token"
                element={<Portfolio uri="tokens" />}
              />
              <Route path="/portfolio/nft/:asset" element={<NftCollection />} />
              <Route path="/portfolio/nft" element={<Portfolio uri="nfts" />} />
              <Route
                path="/portfolio/staking"
                element={<Portfolio uri="staking" />}
              />
            </Routes>
          </main>
        </div>
      </Router>
    </CardanoWalletProvider>
  );
}

export default App;
