import { Link } from "react-router-dom";
import useFetchAccountData from "../../../hooks/useFetchAddressData";
import { numberOfDecimals } from "../../../utils/general";
import NftSkeleton from "./Skeleton";

function Nfts({ currentPrices, stakeAddress }) {
  const nftData = useFetchAccountData(
    `/api/portfolio/account/nft/${stakeAddress}`
  );

  const portfolioValue = nftData?.data.reduce((acc, nft) => {
    acc += nft.floor;
    return acc;
  }, 0);

  const nfts = nftData?.data.reduce((acc, nft) => {
    if (!acc[nft.policy_id]) {
      acc[nft.policy_id] = [nft];
    } else {
      acc[nft.policy_id].push(nft);
    }
    return acc;
  }, {});

  return (
    <>
      {nftData.dataLoaded ? (
        <>
          {/* stats */}
          <div className="flex-col lg:flex-row space-y-2 lg:space-y-0 lg:space-x-2 lg:flex mb-10">
            <div className="w-full lg:w-1/2 p-10 bg-white drop-shadow-sm rounded-lg border">
              <p className="leading-relaxed text-xl font-thin mb-5 uppercase">
                <span className="bg-green-500 px-1 mr-1 h-9"></span>Balance
              </p>
              <p className="text-5xl font-thin">
                {numberOfDecimals(portfolioValue)} ADA
              </p>
              <p className="text-xl font-thin text-gray-400 mt-2">
                {numberOfDecimals(portfolioValue * currentPrices.ada)} USD
              </p>
            </div>
            <div className="w-full lg:w-1/4 p-10  bg-white drop-shadow-sm rounded-lg border">
              <p className="leading-relaxed text-xl font-thin mb-5 uppercase">
                <span className="bg-blue-500 px-1 mr-1 h-9"></span>Collections
              </p>
              <p className="text-5xl font-thin">{Object.keys(nfts).length}</p>
            </div>
            <div className="w-full lg:w-1/4 p-10  bg-white drop-shadow-sm rounded-lg border">
              <p className="leading-relaxed text-xl font-thin mb-5 uppercase">
                <span className="bg-yellow-400 px-1 mr-1 h-9"></span>NFTs
              </p>
              <p className="text-5xl font-thin">{nftData.data.length}</p>
            </div>
          </div>

          {/* table */}
          <div className="p-10 drop-shadow-sm bg-white rounded-lg border">
            <div className="flex self-center justify-center w-full overflow-auto">
              <table className="w-full overflow-auto text-md text-left table-auto">
                <thead>
                  <tr>
                    <th className="px-8 py-6">NFT</th>
                    <th className="px-8 py-6">Quantity</th>
                    <th className="px-8 py-6">Floor</th>
                    <th className="px-8 py-6">Value</th>
                    <th className="px-8 py-6">Info</th>
                  </tr>
                </thead>
                <tbody>
                  {nfts &&
                    Object.keys(nfts).map((key) => (
                      <tr key={key} className="border-t hover:bg-slate-100">
                        <td className="px-8 py-6 flex items-center">
                          <Link
                            className="border-red-500"
                            to={`/portfolio/nft/${nfts[key][0].asset}`}
                          >
                            <img
                              className="w-16 h-16 mr-4 rounded-lg bg-black"
                              src={nfts[key][0].image.replace(
                                "ipfs://",
                                "https://ipfs.io/ipfs/"
                              )}
                              alt={nfts[key][0].policy_id}
                            />
                          </Link>
                          <div>
                            <Link
                              className="border-red-500"
                              to={`/portfolio/nft/${nfts[key][0].asset}`}
                            >
                              {nfts[key][0].policy_id}
                            </Link>
                          </div>
                        </td>
                        <td className="px-8 py-6">{nfts[key].length}</td>
                        <td className="px-8 py-6">{nfts[key][0].floor} ₳</td>
                        <td className="px-8 py-6">
                          {nfts[key].reduce((acc, nft) => {
                            acc += nft.floor;
                            return acc;
                          }, 0)}{" "}
                          ₳
                        </td>
                        <td className="px-8 py-6">
                          <Link to={`/portfolio/nft/${nfts[key][0].asset}`}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                            >
                              <path
                                fill="none"
                                stroke="#000"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M3,3v18h18V3H3z M6,9h12 M6,12h12 M6,15h12 M6,6h12"
                              />
                            </svg>
                          </Link>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </>
      ) : (
        <NftSkeleton />
      )}
    </>
  );
}

export default Nfts;
