import FeedDetailDiscord from "./FeedDetailDiscord";
import FeedDetailTwitter from "./FeedDetailTwitter";
import FeedItemYoutube from "./FeedItemYoutube";
import FeedItemBlog from "./FeedDetailBlog";

function FeedDetails({ itemData }) {
  return (
    <div className="hidden lg:block w-3/4 border-l bg-white h-screen overflow-y-auto overscroll-contain">
      <div className="p-10">
        {itemData.platform === "twitter" && (
          <FeedDetailTwitter itemData={itemData} />
        )}

        {itemData.platform === "discord" && (
          <FeedDetailDiscord itemData={itemData} />
        )}

        {itemData.platform === "youtube" && (
          <FeedItemYoutube itemData={itemData} />
        )}

        {itemData.platform === "blog" && <FeedItemBlog itemData={itemData} />}
      </div>
    </div>
  );
}

export default FeedDetails;
