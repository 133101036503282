import React, { createContext } from "react";
import { useCardano } from "@cardano-foundation/cardano-connect-with-wallet";

/**
 ConnectWalletButton, ConnectWalletList, EnablementFailedError, InjectWalletListener,
 NetworkType, Observable, UnavailableWalletVisibility, WalletExtensionNotFoundError, WalletNotCip30CompatibleError,
 WrongNetworkTypeError, checkIsMobile, decodeHexAddress, estimateAvailableWallets, getWalletIcon, isWalletInstalled,
 useCardano, useLocalStorage)
 */

const CardanoWalletContext = createContext();
const CardanoWalletProvider = ({ children }) => {
  const {
    isConnected,
    isConnecting,
    isEnabled,
    enabledWallet,
    stakeAddress,
    connect,
    disconnect,
  } = useCardano();

  const handleConnect = () => {
    const success = () => {
      console.log("connected successfully");
    };
    const error = (err) => {
      console.log("wallet connect", err);
    };
    connect("eternl", success, error);
  };

  return (
    <CardanoWalletContext.Provider
      value={{
        isConnected,
        isConnecting,
        isEnabled,
        enabledWallet,
        stakeAddress,
        connect: handleConnect,
        disconnect,
      }}
    >
      {children}
    </CardanoWalletContext.Provider>
  );
};

export { CardanoWalletContext, CardanoWalletProvider };
