import { useContext } from "react";
import { ConnectWalletList } from "@cardano-foundation/cardano-connect-with-wallet";
import { CardanoWalletContext } from "../../context/AuthenticationContextProvider";

function WalletLogin() {
  const cardanoWallet = useContext(CardanoWalletContext);
  return (
    <>
      {/* show connect button or loading text */}
      {cardanoWallet.isConnecting && "Loading Wallet..."}
      {!cardanoWallet.isConnected && !cardanoWallet.isConnecting && (
        <ConnectWalletList
          onConnect={cardanoWallet.connect}
          customCSS={`
                  font-size: 0.875rem;
                  display: flex;
                  gap: 0.5rem;
                  border: 0px solid red;
                  height: 48px;
                  & > span {
                    padding: 5px 10px;
                    border: 0px solid red;
                    border-bottom: 0px solid red;
                    border-top: 0px solid red;
                    border-right: 0px solid red;
                    border-left: 0px solid red;
                  }
                  & > span:last-child {
                    padding: 5px 10px;
                    border: 0px solid red;
                    border-bottom: 0px solid red;
                    border-top: 0px solid red;
                    border-right: 0px solid red;
                    border-left: 0px solid red;
                  }
              `}
        />
      )}
      {/* show loaded wallet data */}
      {cardanoWallet.isConnected ? (
        <div className="mt-12">
          <div className="py-2">
            {cardanoWallet.stakeAddress &&
              cardanoWallet.stakeAddress.slice(0, 10)}
            ...
            {cardanoWallet.stakeAddress &&
              cardanoWallet.stakeAddress.slice(
                cardanoWallet.stakeAddress.length - 6,
                cardanoWallet.stakeAddress.length
              )}
          </div>
          <div className="py-2">
            <button onClick={cardanoWallet.disconnect}>
              Disconnect Wallet
            </button>
          </div>
        </div>
      ) : null}
    </>
  );
}

export default WalletLogin;
