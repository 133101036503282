function MenuSection(props) {
  return (
    <div className="mt-8 px-4">
      <h4 className="text-md tracking-wider text-black font-bold uppercase my-1 ml-3 mb-4">
        {props.title}
      </h4>
      <ul>{props.children}</ul>
    </div>
  );
}

export default MenuSection;
