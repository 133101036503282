import useTimePassed from "../../hooks/useTimePassed";

function FeedItemYoutube({ itemData }) {
  const timepassed = useTimePassed(itemData.platform_date);

  return (
    <div>
      <div className="flex items-center space-x-5 mb-10">
        <div>
          <img
            className="rounded-full w-24 h-24"
            alt={itemData.video_user}
            src={itemData.video_user_image}
          />
        </div>
        <div className="space-y-1">
          <div>
            <span className="inline-flex h-3 w-3 rounded-full mr-1 border-2 bg-[#ff9999] border-[#ff3333]"></span>
          </div>
          <div className="text-xl font-bold">
            {itemData.video_channel_title}
          </div>
          <div className="text-sm font-medium text-gray-400">{timepassed}</div>
        </div>
      </div>

      <h1 className="text-2xl font-bold mb-5">{itemData.video_title}</h1>

      <div className="relative pb-[56.25%] mb-5">
        <iframe
          className="absolute top-0 left-0 w-full h-full"
          src={`https://www.youtube-nocookie.com/embed/${itemData.video_id}`}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>
      </div>

      <div className="mb-5 space-x-3">
        <button className="py-1 px-3 mr-1 rounded-md border bg-gray-100 text-gray-400 font-medium tracking-wide hover:text-gray-600 hover:border-gray-600">
          <a
            href={`https://www.youtube.com/watch?v=${itemData.video_id}`}
            target={"_blank"}
            rel={"noreferrer"}
          >
            View Video
          </a>
        </button>

        <button className="py-1 px-3 mr-1 rounded-md border bg-gray-100 text-gray-400 font-medium tracking-wide hover:text-gray-600 hover:border-gray-600">
          <a
            href={`https://www.youtube.com/${itemData.video_user}`}
            target={"_blank"}
            rel={"noreferrer"}
          >
            View Profile
          </a>
        </button>
      </div>

      <div className="break-words" style={{ whiteSpace: "pre-line" }}>
        {itemData.video_description}
      </div>
    </div>
  );
}

export default FeedItemYoutube;
