import useTimePassed from "../../hooks/useTimePassed";
import { capitalizeFirstLetter } from "../../utils/general";

function FeedDetailDiscord({ itemData }) {
  const timepassed = useTimePassed(itemData.platform_date);
  const logos = {
    "950173135838273556":
      "https://cdn.discordapp.com/icons/950173135838273556/344a78362e6dbdb4c7461ef895049d05.webp?size=96",
    "918870284331802674":
      "https://cdn.discordapp.com/icons/918870284331802674/2bc98b8648131e1ea66e4a1ae301fdcc.webp?size=96",
    "826816523368005654":
      "https://cdn.discordapp.com/icons/826816523368005654/5fe345f370ee9016deeaa1f161f1a62a.webp?size=96",
    "1033727051158536242":
      "https://cdn.discordapp.com/icons/1033727051158536242/44d6992f3ecfa0dbdaeea62a2288a9ba.webp?size=96",
    "907178289263681566":
      "https://cdn.discordapp.com/icons/907178289263681566/131bfa832ca6021021aed3600d314325.webp?size=96",
    "907677998633844756":
      "https://cdn.discordapp.com/icons/907677998633844756/fdd632e206b30f630f04e47d7b28291e.webp?size=96",
    "907023193942917222":
      "https://cdn.discordapp.com/icons/907023193942917222/a_0794f254dba5db71b1c14bb290c2e873.webp?size=96",
    "857911582972968981":
      "https://cdn.discordapp.com/icons/857911582972968981/becce4790f2a2632bf9800e227f488f1.webp?size=96",
    "968124541534601266":
      "https://cdn.discordapp.com/icons/968124541534601266/a_d77d6c83177e829a36de4cf76087cb3e.webp?size=96",
    "909843832491896832":
      "https://cdn.discordapp.com/icons/909843832491896832/13d402d439761a49094a1655ab711272.webp?size=96",
    "992492730339037295":
      "https://cdn.discordapp.com/icons/992492730339037295/089bc43cb949b8d6b215f0ceac10e21b.webp?size=96",
    "923618392911536218":
      "https://cdn.discordapp.com/icons/923618392911536218/b0bfd153ad5a59fd182602c2eeb1e0b7.webp?size=96",
    "967512258877984798":
      "https://cdn.discordapp.com/icons/967512258877984798/a064bd8bf1919c6efabb09c9bd2e16a3.webp?size=96",
    "940613402928230430":
      "https://cdn.discordapp.com/icons/940613402928230430/12b2acf68629b725800793ad8cc6a428.webp?size=96",
    "944483563116564491":
      "https://cdn.discordapp.com/icons/944483563116564491/83e1a4f520ea9c2105941deba582da57.webp?size=96",
    "843173927831470170":
      "https://cdn.discordapp.com/icons/843173927831470170/36ee14ea52b5ada11584d845398d215b.webp?size=96",
    "851910000003645490":
      "https://cdn.discordapp.com/icons/851910000003645490/8a0eefa2d510a3d5c4bb87121409de21.webp?size=96",
    "881237412628533258":
      "https://cdn.discordapp.com/icons/881237412628533258/828a0de3e61714b7f859167596abfa68.webp?size=96",
    "885529372797313096":
      "https://cdn.discordapp.com/icons/885529372797313096/ac5de53eeecf73ce4f4c51553358c03e.webp?size=96",
    "829060079248343090":
      "https://cdn.discordapp.com/icons/829060079248343090/233bca52de136e2e12750035f224aa04.webp?size=96",
    "915937361286795334":
      "https://cdn.discordapp.com/icons/915937361286795334/0acf8e3e4bebf4cc103c7cc4c215bc2c.webp?size=96",
    "897161313120321536":
      "https://cdn.discordapp.com/icons/897161313120321536/0bfae98d5634e745635c2f4cd8cf88a2.webp?size=96",
    830939534174453800:
      "https://cdn.discordapp.com/icons/830939534174453800/d5a2b4773d8b5c9b377d77a75bc5140a.webp?size=96",
    "819877190786744360":
      "https://cdn.discordapp.com/icons/819877190786744360/d0261c4662f0d9a4238ab910cf7644dc.webp?size=96",
    "915703744178573372":
      "https://cdn.discordapp.com/icons/915703744178573372/514acd5d6b5f5c519707501e1b119288.webp?size=96",
    "834847641463947364":
      "https://cdn.discordapp.com/icons/834847641463947364/a_da98944634cf1e9366b18f8e43867dfe.webp?size=96",
    "853921427286654987":
      "https://cdn.discordapp.com/icons/853921427286654987/eb53871e852ff3065d95cc15c6d147b2.webp?size=96",
    "850372362033430539":
      "https://cdn.discordapp.com/icons/850372362033430539/0008197367503f9d1cc943507ae41418.webp?size=96",
    "759807412688388136":
      "https://cdn.discordapp.com/icons/759807412688388136/3af467b1658c6f9170712e6b5e04542b.webp?size=96",
    "829374949587419137":
      "https://cdn.discordapp.com/icons/829374949587419137/c01a482a90bbf2e609fd85ff98ce7d31.webp?size=96",
    "917849487794397254":
      "https://cdn.discordapp.com/icons/917849487794397254/8af8aab134002729ad96622f39363263.webp?size=96",
    "837215135999197246":
      "https://cdn.discordapp.com/icons/837215135999197246/83bf4deeaf11cc78fef248370611daf1.webp?size=96",
    "816779565796032513":
      "https://cdn.discordapp.com/icons/816779565796032513/a_c4327631c538b7bef1640b5d5090c9bf.webp?size=96",
    "876475955701501962":
      "https://cdn.discordapp.com/icons/876475955701501962/e17701cc708b6877b803471caf8a41a3.webp?size=96",
  };

  return (
    <div>
      <div className="flex items-center space-x-5 mb-10">
        <div>
          <img
            className="rounded-full w-24 h-24 border border-gray-200"
            alt={itemData.message_server_id}
            src={logos[itemData.message_server_id]}
          />
        </div>
        <div className="space-y-1">
          <div>
            <span className="inline-flex h-3 w-3 rounded-full mr-1 border-2 bg-[#bcc1fa] border-[#7984f5]"></span>
          </div>
          <div className="text-xl font-bold">
            {capitalizeFirstLetter(itemData.message_project)}
          </div>
          <div className="text-sm font-medium text-gray-400">{timepassed}</div>
        </div>
      </div>

      <h1 className="text-2xl font-bold mb-5">
        {capitalizeFirstLetter(itemData.message_project)} Discord announcement.
      </h1>

      <div className="mb-10 space-x-3">
        <button className="py-1 px-3 mr-1 rounded-md border bg-gray-100 text-gray-400 font-medium tracking-wide hover:text-gray-600 hover:border-gray-600">
          <a
            href={`https://discord.com/channels/${itemData.message_server_id}/${itemData.message_channel_id}`}
            target={"_blank"}
            rel={"noreferrer"}
          >
            View Channel
          </a>
        </button>

        <button className="py-1 px-3 mr-1 rounded-md border bg-gray-100 text-gray-400 font-medium tracking-wide hover:text-gray-600 hover:border-gray-600">
          <a
            href={`https://discord.com/channels/${itemData.message_server_id}/${itemData.message_channel_id}/${itemData.message_id}`}
            target={"_blank"}
            rel={"noreferrer"}
          >
            View Message
          </a>
        </button>
      </div>

      <div className="break-words" style={{ whiteSpace: "pre-line" }}>
        {itemData.message_text}
      </div>
    </div>
  );
}

export default FeedDetailDiscord;
