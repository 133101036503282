import { useState } from "react";
import { debounce } from "lodash"; // import the debounce function from Lodash

function useSearchMessages(url) {
  const [searchResults, setSearchResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const searchDiscordDebounced = debounce(async (text, database) => {
    setIsLoading(true);

    try {
      const response = await fetch(url);
      const data = await response.json();
      setSearchResults(data);
    } catch (error) {
      console.error(error);
    }

    setIsLoading(false);
  }, 1000);

  // call searchDiscordDebounced instead of searchDiscord
  function searchDiscord(text, database) {
    searchDiscordDebounced(text, database);
  }

  return { searchResults, isLoading, searchDiscord };
}

export default useSearchMessages;
