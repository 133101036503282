import { useState, useEffect } from "react";

function useDetectScroll(ref) {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const div = ref.current;
      const isDivScrolled = div.scrollTop > 0;
      setIsScrolled(isDivScrolled);
    };

    const currentRef = ref.current;
    if (currentRef) {
      currentRef.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (currentRef) {
        currentRef.removeEventListener("scroll", handleScroll);
      }
    };
  }, [ref]);

  return isScrolled;
}

export default useDetectScroll;
