import useTimePassed from "../../hooks/useTimePassed";
import { capitalizeFirstLetter } from "../../utils/general";

function FeedDetailBlog({ itemData }) {
  const timepassed = useTimePassed(itemData.platform_date);

  console.log(itemData);
  const blogLogos = {
    ambcrypto: "https://i.imgur.com/iKiLFHv.jpg",
    beincrypto: "https://i.imgur.com/kw7HANm.jpg",
    bitcoincom: "https://i.imgur.com/MX13MrE.jpg",
    bitcoinist: "https://i.imgur.com/PUqSE8g.jpg",
    cryptobriefing: "https://i.imgur.com/cvtrtgj.jpg",
    cryptonews: "https://i.imgur.com/YLnb7Sx.jpg",
    cryptoglobe: "https://i.imgur.com/fFAwsJy.jpg",
    cryptopotato: "https://i.imgur.com/RqAIA2D.jpg",
    cryptoslate: "https://i.imgur.com/mr7nKxC.jpg",
    dailycoin: "https://i.imgur.com/bWIL8zV.png",
    dailyhodl: "https://i.imgur.com/XA0V1MI.jpg",
    forkastnews: "https://i.imgur.com/skL6MdC.jpg",
    newsbtc: "https://i.imgur.com/rVcjgWw.jpg",
    utoday: "https://i.imgur.com/QhxRShP.jpg",
    watcherguru: "https://i.imgur.com/HZxJu5W.png",
    zycrypto: "https://i.imgur.com/GZWueHm.jpg",
  };

  return (
    <div>
      <div className="flex items-center space-x-5 mb-10">
        <div>
          <img
            className="rounded-full w-24 h-24 border border-gray-200"
            alt={itemData.article_publisher}
            src={blogLogos[itemData.article_publisher]}
          />
        </div>
        <div className="space-y-1">
          <div>
            <span className="inline-flex h-3 w-3 rounded-full mr-1 border-2 bg-[#c5da83] border-[#9FC131]"></span>
          </div>
          <div className="text-xl font-bold">
            {capitalizeFirstLetter(itemData.article_publisher)}
          </div>
          <div className="text-sm font-medium text-gray-400">{timepassed}</div>
        </div>
      </div>

      <h1 className="text-2xl font-bold mb-5">
        {capitalizeFirstLetter(itemData.article_title)}
      </h1>

      <div className="mb-10 space-x-3">
        <button className="py-1 px-3 mr-1 rounded-md border bg-gray-100 text-gray-400 font-medium tracking-wide hover:text-gray-600 hover:border-gray-600">
          <a href={itemData.article_link} target={"_blank"} rel={"noreferrer"}>
            View Article
          </a>
        </button>

        <button className="py-1 px-3 mr-1 rounded-md border bg-gray-100 text-gray-400 font-medium tracking-wide hover:text-gray-600 hover:border-gray-600">
          <a
            href={itemData.article_website}
            target={"_blank"}
            rel={"noreferrer"}
          >
            View Website
          </a>
        </button>
      </div>

      <div className="break-words" style={{ whiteSpace: "pre-line" }}>
        {itemData.article_text}
      </div>
    </div>
  );
}

export default FeedDetailBlog;
