import { useRef, useState, useEffect, useCallback } from "react";
import FeedDetails from "./FeedDetails";
import FeedItem from "./FeedItem";
import { useWebSocket } from "../../hooks/useWebSocket";
import useData from "../../hooks/useApi";
import useSortMessages from "../../hooks/useSortMessages";
import useDetectScroll from "../../hooks/useDetectScroll";
import FeedSearch from "./FeedSearch";

function Feed({ uri }) {
  // state
  const [searchMessages, setSearchMessages] = useState([]);
  const [currentItem, setCurrentItem] = useState({});
  const [selectedItemIndex, setSelectedItemIndex] = useState(0);
  // references
  const feedParent = useRef(null);
  const feed = useRef(null);
  // hooks
  const isScrolled = useDetectScroll(feedParent);
  // process.env.REACT_APP_SOCKET_URL
  const { messages } = useWebSocket("wss://tornado:8888/websocket");
  const { data, setPageNumber } = useData(`/api/feed/${uri}`, 15);
  const allMessages = useSortMessages(messages, data, uri);

  useEffect(() => {
    setSelectedItemIndex(0);
  }, [uri]);

  useEffect(() => {
    if (allMessages !== undefined && allMessages[0]) {
      setCurrentItem(allMessages[0]);
    }
  }, [allMessages]);

  useEffect(() => {
    const myDiv = feedParent.current;
    function handleScroll() {
      if (myDiv.scrollTop + myDiv.clientHeight >= myDiv.scrollHeight) {
        handleNextPage();
      }
    }
    myDiv.addEventListener("scroll", handleScroll);
    return () => {
      myDiv.removeEventListener("scroll", handleScroll);
    };
  });

  const handleScrollTop = () => {
    feed.current.scrollIntoView({ behavior: "smooth" });
  };

  const handleNextPage = () => {
    setPageNumber((pageNumber) => pageNumber + 1);
  };

  const handleViewItem = (data_item, index) => {
    setCurrentItem(data_item);
    setSelectedItemIndex(index);
  };

  const handleSetResults = useCallback((handleSearch) => {
    setSearchMessages(handleSearch);
  }, []);

  return (
    <>
      <div
        ref={feedParent}
        className="w-full lg:w-7/12 bg-slate-100 h-screen overflow-y-auto overscroll-contain"
      >
        <div
          ref={feed}
          className=" bg-slate-100 drop-shadow-none py-6 px-2 border-b"
        >
          <FeedSearch database={uri} handleSetResults={handleSetResults} />
        </div>

        <div className="drop-shadow-md">
          {searchMessages.length === 0 ? (
            <>
              {allMessages &&
                allMessages.map((item, index) => (
                  <div
                    onClick={() => handleViewItem(item, index)}
                    className={`hover:bg-white px-3 py-3 border-b ${
                      selectedItemIndex === index ? "bg-white" : ""
                    }`}
                    key={item.platform_id + index}
                  >
                    <FeedItem feedType={item.platform} data={item} />
                  </div>
                ))}
            </>
          ) : (
            <>
              {searchMessages.map((item, index) => (
                <div
                  onClick={() => handleViewItem(item, index)}
                  className={`hover:bg-white px-3 py-3 border-b ${
                    selectedItemIndex === index ? "bg-white" : ""
                  }`}
                  key={item.platform_id + index}
                >
                  <FeedItem feedType={item.platform} data={item} />
                </div>
              ))}
            </>
          )}
        </div>

        {isScrolled ? (
          <div className="sticky bottom-0 z-50">
            <div className="text-xs bg-black text-white font-bold text-center">
              <button
                className="w-full uppercase tracking-widest p-2"
                onClick={handleScrollTop}
              >
                Scroll To The Top
              </button>
            </div>
          </div>
        ) : null}
      </div>

      <FeedDetails itemData={currentItem} />
    </>
  );
}

export default Feed;
