import { useContext } from "react";
import Tokens from "../components/portfolio/tokens/Tokens";
import Nfts from "../components/portfolio/nfts/Nfts";
import Staking from "../components/portfolio/staking/Staking";
import { CardanoWalletContext } from "../context/AuthenticationContextProvider";

function Portfolio({ uri }) {
  const cardanoWallet = useContext(CardanoWalletContext);

  const currentPrices = {
    ada: 0.34,
    djed: 1.002,
  };

  return (
    <div className="p-10 bg-slate-100 h-screen overflow-y-auto overscroll-contain min-w-full">
      {cardanoWallet.stakeAddress ? (
        <>
          {uri === "tokens" && (
            <Tokens
              currentPrices={currentPrices}
              stakeAddress={cardanoWallet.stakeAddress}
            />
          )}

          {uri === "nfts" && (
            <Nfts
              currentPrices={currentPrices}
              stakeAddress={cardanoWallet.stakeAddress}
            />
          )}

          {uri === "staking" && (
            <Staking
              currentPrices={currentPrices}
              stakeAddress={cardanoWallet.stakeAddress}
            />
          )}
        </>
      ) : (
        <div>Connect a wallet.</div>
      )}
    </div>
  );
}

export default Portfolio;
