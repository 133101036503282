function Logo() {
  return (
    <div className="flex justify-center uppercase text-3xl">
      <span className="font-thin tracking-wider text-black">Crypto</span>
      <span className="font-extrabold tracking-wider text-blue-600">Sift</span>
    </div>
  );
}

export default Logo;
