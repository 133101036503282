import useTimePassed from "../../hooks/useTimePassed";
import { capitalizeFirstLetter } from "../../utils/general";

function FeedDetailTwitter({ itemData }) {
  const timepassed = useTimePassed(itemData.platform_date);

  return (
    <div>
      <div className="flex items-center space-x-5 mb-10">
        <div>
          <img
            className="rounded-full w-24 h-24 border border-gray-200"
            alt={itemData.space_user}
            src={itemData.space_user_image.replace("_normal", "")}
          />
        </div>
        <div className="space-y-1">
          <div>
            <span className="inline-flex h-3 w-3 rounded-full mr-1 border-2 bg-[#a5d9fa] border-[#4ab4f5]"></span>
          </div>
          <div className="text-xl font-bold">
            @{capitalizeFirstLetter(itemData.space_user)}
          </div>
          <div className="text-sm font-medium text-gray-400">{timepassed}</div>
        </div>
      </div>

      <h1 className="text-2xl font-bold mb-5">{itemData.space_title}</h1>

      <div className="mb-10 space-x-3">
        <button className="py-1 px-3 mr-1 rounded-md border bg-gray-100 text-gray-400 font-medium tracking-wide hover:text-gray-600 hover:border-gray-600">
          <a
            href={`https://twitter.com/i/spaces/${itemData.space_id}`}
            target={"_blank"}
            rel={"noreferrer"}
          >
            View Space
          </a>
        </button>

        <button className="py-1 px-3 mr-1 rounded-md border bg-gray-100 text-gray-400 font-medium tracking-wide hover:text-gray-600 hover:border-gray-600">
          <a
            href={`https://twitter.com/${itemData.space_user}`}
            target={"_blank"}
            rel={"noreferrer"}
          >
            View Profile
          </a>
        </button>
      </div>

      <div className="break-words" style={{ whiteSpace: "pre-line" }}>
        {itemData.video_description}
      </div>
    </div>
  );
}

export default FeedDetailTwitter;
