import React from "react";
import useCountdown from "../../../hooks/useCountdown";
import useFetchAccountData from "../../../hooks/useFetchAddressData";
import { numberOfDecimals } from "../../../utils/general";
import StakingSkeleton from "./Skeleton";

function Staking({ currentPrices, stakeAddress }) {
  const stakingData = useFetchAccountData(
    `/api/portfolio/account/rewards/${stakeAddress}`
  );

  const poolId = stakingData?.data[0]?.pool_id;
  const poolData = useFetchAccountData(
    poolId ? `/api/pool/staked/${poolId}` : ""
  );

  const epochData = useFetchAccountData(`/api/epoch/current`);

  stakingData.data.sort((a, b) => b.epoch - a.epoch);

  const totalRewards = stakingData.data.reduce((totalAda, epoch) => {
    return (totalAda = totalAda + epoch.amount / 1000000);
  }, 0);

  const EpochCountdown = React.memo(({ timestamp }) => {
    const { days, hours, minutes, seconds } = useCountdown(timestamp);
    return `${days}:${hours}:${minutes}:${seconds}`;
  });

  return (
    <>
      {poolData.dataLoaded ? (
        <>
          {/* stats */}
          <div className="flex-col lg:flex-row space-y-2 lg:space-y-0 lg:space-x-2 lg:flex mb-10">
            <div className="w-full lg:w-1/2 p-10 bg-white drop-shadow-sm rounded-lg border">
              <p className="leading-relaxed text-xl font-thin mb-5 uppercase">
                <span className="bg-green-400 px-1 mr-1 h-9"></span>Pool
              </p>
              <p className="text-5xl font-thin">
                {poolData.loading
                  ? ""
                  : `(${poolData?.data?.metadata?.ticker}) ${poolData?.data?.metadata?.name}`}
              </p>
              <p className="text-xl font-thin text-gray-400 mt-2">
                {poolData.loading ? "" : poolData?.data?.metadata?.homepage}
              </p>
            </div>
            <div className="w-full lg:w-1/4 p-10  bg-white drop-shadow-sm rounded-lg border">
              <p className="leading-relaxed text-xl font-thin mb-5 uppercase">
                <span className="bg-blue-400 px-1 mr-1 h-9"></span>Rewards
              </p>
              <p className="text-5xl font-thin">
                {numberOfDecimals(totalRewards)} ADA
              </p>
              <p className="text-xl font-thin text-gray-400 mt-2">
                {numberOfDecimals(totalRewards * currentPrices.ada)} USD
              </p>
            </div>
            <div className="w-full lg:w-1/4 p-10  bg-white drop-shadow-sm rounded-lg border">
              <p className="leading-relaxed text-xl font-thin mb-5 uppercase">
                <span className="bg-yellow-400 px-1 mr-1 h-9"></span>Epoch
              </p>
              <p className="text-5xl font-thin">{epochData?.data?.epoch}</p>
              <p className="text-xl font-thin text-gray-400 mt-2">
                <EpochCountdown timestamp={epochData?.data?.end_time} />
              </p>
            </div>
          </div>
          {/* stake pool */}
          <div className="p-10 mb-10 bg-white drop-shadow-sm rounded-lg border">
            {/* pool stats */}
            <div className="grid grid-cols-3 gap-4 font-thin tracking-wider">
              <div className="bg-slate-100 rounded-lg px-10 py-4 w-full">
                <div className=" text-lg font-thin">Live Stake</div>
                <div className="font-medium">
                  {numberOfDecimals(poolData?.data?.live_stake / 1000000)} ₳
                </div>
              </div>
              <div className="bg-slate-100 rounded-lg px-10 py-4 w-full">
                <div className=" text-lg font-thin">Active Stake</div>
                <div className="font-medium">
                  {numberOfDecimals(poolData?.data?.active_stake / 1000000)} ₳
                </div>
              </div>
              <div className="bg-slate-100 rounded-lg px-10 py-4 w-full">
                <div className=" text-lg font-thin">Pledge</div>
                <div className="font-medium">
                  {numberOfDecimals(poolData?.data?.declared_pledge / 1000000)}{" "}
                  ₳
                </div>
              </div>
              <div className="bg-slate-100 rounded-lg px-10 py-4 w-full">
                <div className=" text-lg font-thin">Current Blocks</div>
                <div className="font-medium">
                  {poolData?.data?.blocks_epoch}
                </div>
              </div>
              <div className="bg-slate-100 rounded-lg px-10 py-4 w-full">
                <div className=" text-lg font-thin">Lifetime Blocks</div>
                <div className="font-medium">
                  {poolData?.data?.blocks_minted}
                </div>
              </div>
              <div className="bg-slate-100 rounded-lg px-10 py-4 w-full">
                <div className=" text-lg font-thin">Delagators</div>
                <div className="font-medium">
                  {poolData?.data?.live_delegators}
                </div>
              </div>
              <div className="bg-slate-100 rounded-lg px-10 py-4 w-full">
                <div className=" text-lg font-thin">Fixed Cost</div>
                <div className="font-medium">
                  {poolData?.data?.fixed_cost / 1000000} ₳
                </div>
              </div>
              <div className="bg-slate-100 rounded-lg px-10 py-4 w-full">
                <div className=" text-lg font-thin">Margin Cost</div>
                <div className="font-medium">
                  {poolData?.data?.margin_cost * 100}%
                </div>
              </div>
              <div className="bg-slate-100 rounded-lg px-10 py-4 w-full"></div>
            </div>

            {/* bar */}
            <div className="flex mt-4">
              <div className="relative bg-slate-100 h-10 w-full rounded-lg">
                <div
                  className={
                    poolData?.data?.live_saturation * 100 < 99
                      ? "h-10 rounded-l-lg"
                      : "h-10 rounded-lg"
                  }
                  style={{
                    width: `${poolData?.data?.live_saturation * 100}%`,
                    backgroundColor:
                      poolData?.data?.live_saturation * 100 < 70
                        ? "#4ade80"
                        : poolData?.data?.live_saturation * 100 >= 70 &&
                          poolData?.data?.live_saturation * 100 <= 80
                        ? "#f97316"
                        : "#ef4444",
                  }}
                ></div>
                <span
                  className="absolute inset-0 flex items-center justify-center font-medium tracking-wider"
                  style={{ zIndex: 1 }}
                >
                  {numberOfDecimals(poolData?.data?.live_saturation * 100)}%
                  Saturation
                </span>
              </div>
            </div>
          </div>

          {/* table */}
          <div className="p-10 drop-shadow-sm bg-white rounded-lg border">
            <div className="flex self-center justify-center w-full overflow-auto">
              <table className="w-full overflow-auto text-md text-left table-auto">
                <thead>
                  <tr>
                    <th className="px-8 py-6">Pool</th>
                    <th className="px-8 py-6">Epoch</th>
                    <th className="px-8 py-6">Reward</th>
                  </tr>
                </thead>
                <tbody>
                  {stakingData?.data.map((reward) => (
                    <tr
                      key={reward?.epoch}
                      className="border-t hover:bg-slate-100"
                    >
                      <td className="px-8 py-6">{reward?.pool_id}</td>
                      <td className="px-8 py-6">{reward?.epoch}</td>
                      <td className="px-8 py-6">
                        {reward?.amount / 1000000} ₳
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </>
      ) : (
        <StakingSkeleton />
      )}
    </>
  );
}
export default Staking;
