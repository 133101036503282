function TokenSkeleton() {
  return (
    <>
      {/* stats */}
      <div className="flex-col lg:flex-row space-y-2 lg:space-y-0 lg:space-x-2 lg:flex mb-10 animate-pulse">
        <div className="w-full lg:w-1/2 p-10 bg-white drop-shadow-sm rounded-lg border">
          <p className="leading-relaxed text-xl font-thin mb-5 uppercase">
            <span className="bg-green-500 px-1 mr-1 h-9"></span>Balance
          </p>
          <div className="text-5xl font-thin">
            <div className="h-16 bg-slate-400 w-full"></div>
          </div>
          <div className="text-xl font-thin text-gray-400 mt-2">
            <div className="h-3 bg-slate-400 w-full"></div>
          </div>
        </div>
        <div className="w-full lg:w-1/4 p-10  bg-white drop-shadow-sm rounded-lg border">
          <p className="leading-relaxed text-xl font-thin mb-5 uppercase">
            <span className="bg-blue-500 px-1 mr-1 h-9"></span>ADA Price
          </p>
          <div className="text-5xl font-thin">
            <div className="h-16 bg-slate-400 w-full"></div>
          </div>
          <div className="text-xl font-thin text-gray-400 mt-2">
            <div className="h-3 bg-slate-400 w-full"></div>
          </div>
        </div>
        <div className="w-full lg:w-1/4 p-10  bg-white drop-shadow-sm rounded-lg border">
          <p className="leading-relaxed text-xl font-thin mb-5 uppercase">
            <span className="bg-yellow-400 px-1 mr-1 h-9"></span>DJED Price
          </p>
          <div className="text-5xl font-thin">
            <div className="h-16 bg-slate-400 w-full"></div>
          </div>
          <div className="text-xl font-thin text-gray-400 mt-2">
            <div className="h-3 bg-slate-400 w-full"></div>
          </div>
        </div>
      </div>

      {/* portfolio */}
      <div className="p-10 mb-10 bg-white drop-shadow-sm rounded-lg border animate-pulse">
        {/* bar */}
        <div className="bg-slate-400 px-10 py-4 w-full h-10"></div>

        {/* tokens */}
        <div className="flex flex-wrap gap-7 mt-9">
          {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((token) => (
            <div key={token} className="flex items-center">
              <span className="h-5 w-28 mr-1 bg-slate-400"></span>
            </div>
          ))}
        </div>
      </div>

      {/* table */}
      <div className="p-10 drop-shadow-sm bg-white rounded-lg border animate-pulse">
        <div className="flex self-center justify-center w-full overflow-auto">
          <table className="w-full overflow-auto text-md text-left table-auto">
            <thead>
              <tr>
                <th className="px-8 py-6">Token</th>
                <th className="px-8 py-6">Ticker</th>
                <th className="px-8 py-6">Price</th>
                <th className="px-8 py-6">Supply</th>
                <th className="px-8 py-6">Holdings</th>
                <th className="px-8 py-6">Value</th>
              </tr>
            </thead>
            <tbody>
              {[1, 2, 3, 4, 5].map((item) => (
                <tr key={item} className="border-t hover:bg-slate-100">
                  <td className="px-8 py-6">
                    <div className="h-5 w-28 mr-1 bg-slate-400"></div>
                  </td>
                  <td className="px-8 py-6">
                    <div className="h-5 w-28 mr-1 bg-slate-400"></div>
                  </td>
                  <td className="px-8 py-6">
                    <div className="h-5 w-28 mr-1 bg-slate-400"></div>
                  </td>
                  <td className="px-8 py-6">
                    <div className="h-5 w-28 mr-1 bg-slate-400"></div>
                  </td>
                  <td className="px-8 py-6">
                    <div className="h-5 w-28 mr-1 bg-slate-400"></div>
                  </td>
                  <td className="px-8 py-6">
                    <div className="h-5 w-28 mr-1 bg-slate-400"></div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

export default TokenSkeleton;
