import { useState, useEffect } from "react";

function useFetchAccountData(uri) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [dataLoaded, setDataLoaded] = useState(false);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetch(uri);
        const json = await response.json();
        setData(json);
        setDataLoaded(true);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    }

    if (uri) {
      fetchData();
    }
  }, [uri]);

  return { data, loading, error, dataLoaded };
}

export default useFetchAccountData;
