import React, { useEffect, useState } from "react";
import useSearchMessages from "../../hooks/useSearchMessages";

function FeedSearch({ database, handleSetResults }) {
  const [text, setText] = useState("");
  const { searchResults, searchDiscord } = useSearchMessages(
    `/api/search/${database}?q=${text}`
  );

  function handleTextChange(event, database) {
    setText(event.target.value);
    searchDiscord(event.target.value, database);
  }

  function handleClearInput() {
    setText("");
    handleSetResults([]);
  }

  useEffect(() => {
    setText("");
  }, [database]);

  useEffect(() => {
    if (text === "") {
      handleSetResults([]);
    } else {
      handleSetResults(searchResults);
    }
  }, [text, database, searchResults, handleSetResults]);

  return (
    <div className="relative">
      <input
        className="w-full pl-10 pr-4 bg-transparent focus:outline-none"
        type="text"
        placeholder="Search"
        onChange={(event) => handleTextChange(event, database)}
        value={text}
      />
      {text !== "" && (
        <div
          className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
          onClick={handleClearInput}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 16 16"
            className="bi bi-x"
            width="16"
            height="16"
          >
            <path
              fillRule="evenodd"
              d="M8 7.293l3.646-3.647a1 1 0 0 1 1.415 1.414L9.414 8l3.647 3.646a1 1 0 1 1-1.415 1.414L8 9.414l-3.646 3.647a1 1 0 1 1-1.415-1.414L6.586 8 2.939 4.354a1 1 0 0 1 1.415-1.414L8 7.293z"
            />
          </svg>
        </div>
      )}
      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          className="bi bi-search"
          viewBox="0 0 16 16"
        >
          <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
        </svg>
      </div>
    </div>
  );
}

export default FeedSearch;
