import { useParams } from "react-router-dom";
import useFetchAccountData from "../../../hooks/useFetchAddressData";

function NftCollection() {
  const { asset, stakeAddress } = useParams();
  const nft = useFetchAccountData(`/portfolio/asset/${asset}`);

  console.log(">>>>>>>>>>>>>", nft, stakeAddress);
  return (
    <div className="p-10 bg-slate-100 h-screen overflow-y-auto overscroll-contain min-w-full">
      {nft.dataLoaded ? (
        <div className="p-10 drop-shadow-sm bg-white rounded-lg border flex">
          <div className="w-2/3">
            <div>
              <h1 className=" text-4xl font-bold">
                {nft.data.onchain_metadata.name}
              </h1>
              <h3 className="text-lg font-light text-slate-400">
                {nft.data.policy_id}
              </h3>
            </div>
            <div className="mt-10">
              <h2 className="text-2xl font-bold">Value</h2>FLOOR | PRICE PAID |
              OFFERS
            </div>
            <div className="mt-10">
              <h2 className="text-2xl font-bold">Attributes</h2>
              <div className="flex flex-wrap gap-5 mt-5">
                {nft.data.onchain_metadata.attributes &&
                  Object.keys(nft.data.onchain_metadata.attributes).map(
                    (key) => (
                      <div
                        key={key}
                        className="bg-slate-100 rounded-lg w-44 px-3 py-2"
                      >
                        <div className="font-light text-lg text-slate-400">
                          {key}
                        </div>
                        <div className="font-bold text-lg">
                          {nft.data.onchain_metadata.attributes[key]}
                        </div>
                      </div>
                    )
                  )}
              </div>
            </div>
          </div>
          <div className="w-1/3">
            <img
              className="bg-black rounded-lg w-full"
              src={nft.data.onchain_metadata.image.replace(
                "ipfs://",
                "https://ipfs.io/ipfs/"
              )}
              alt={nft.data.onchain_metadata.asset_name}
            />
          </div>
        </div>
      ) : (
        "asdasdasdasdasdas"
      )}
    </div>
  );
}

export default NftCollection;
