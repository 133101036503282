import {
  faCoins,
  faImage,
  faPiggyBank,
} from "@fortawesome/free-solid-svg-icons";
import Logo from "./Logo";
import MenuSection from "./MenuSection";
import MenuItem from "./MenuItem";
import WalletLogin from "./WalletLogin";

function Sidebar() {
  return (
    <nav className="w-64 h-screen bg-white border-r lg:block">
      <div className="flex flex-col justify-between h-full">
        <div id="top" className="mt-5">
          <Logo />

          <MenuSection title="Portfolio">
            <MenuItem
              title="Tokens"
              uri="/portfolio/token"
              type="icon"
              data={faCoins}
            />
            <MenuItem
              title="NFTs"
              uri="/portfolio/nft"
              type="icon"
              data={faImage}
            />
            <MenuItem
              title="Staking"
              uri="/portfolio/staking"
              type="icon"
              data={faPiggyBank}
            />
          </MenuSection>

          <MenuSection title="News">
            <MenuItem
              title="All"
              uri="/news/all"
              type="circle"
              data={["#999", "2px solid #666"]}
            />
            <MenuItem
              title="Discord"
              uri="/news/discord"
              type="circle"
              data={["#bcc1fa", "2px solid #7984f5"]}
            />
            <MenuItem
              title="Youtube"
              uri="/news/youtube"
              type="circle"
              data={["#ff9999", "2px solid #ff3333"]}
            />
            <MenuItem
              title="Spaces"
              uri="/news/twitter"
              type="circle"
              data={["#a5d9fa", "2px solid #4ab4f5"]}
            />
            <MenuItem
              title="Articles"
              uri="/news/blog"
              type="circle"
              data={["#c5da83", "2px solid #9FC131"]}
            />
          </MenuSection>
        </div>
        <div id="bottom" className="flex flex-col items-center">
          <WalletLogin />
        </div>
      </div>
    </nav>
  );
}

export default Sidebar;
