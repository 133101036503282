function useTimePassed(timestamp) {
  const now = new Date().getTime() / 1000; // get current Unix timestamp
  const secondsElapsed = now - timestamp;

  if (secondsElapsed < 60) {
    return `${Math.floor(secondsElapsed)} ${
      Math.floor(secondsElapsed) === 1 ? "second ago" : "seconds ago"
    }`;
  } else if (secondsElapsed < 3600) {
    return `${Math.floor(secondsElapsed / 60)} ${
      Math.floor(secondsElapsed / 60) === 1 ? "minute ago" : "minutes ago"
    }`;
  } else if (secondsElapsed < 86400) {
    return `${Math.floor(secondsElapsed / 3600)} ${
      Math.floor(secondsElapsed / 3600) === 1 ? "hour ago" : "hours ago"
    }`;
  } else if (secondsElapsed < 604800) {
    return `${Math.floor(secondsElapsed / 86400)} ${
      Math.floor(secondsElapsed / 86400) === 1 ? "day ago" : "days ago"
    }`;
  } else if (secondsElapsed < 2419200) {
    return `${Math.floor(secondsElapsed / 604800)} ${
      Math.floor(secondsElapsed / 604800) === 1 ? "week ago" : "weeks ago"
    }`;
  } else if (secondsElapsed < 29030400) {
    return `${Math.floor(secondsElapsed / 2419200)} ${
      Math.floor(secondsElapsed / 2419200) === 1 ? "month ago" : "months ago"
    }`;
  } else {
    return `${Math.floor(secondsElapsed / 29030400)} ${
      Math.floor(secondsElapsed / 29030400) === 1 ? "year ago" : "years ago"
    }`;
  }
}

export default useTimePassed;
