import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

function MenuItem(props) {
  return (
    <li className="text-sm py-2 flex items-center hover:bg-slate-100 hover:rounded-md">
      {props.type === "circle" && (
        <span
          className="ml-3 mr-2 inline-flex h-3 w-3 rounded-full border-2"
          style={{
            backgroundColor: props.data[0],
            border: props.data[1],
          }}
        ></span>
      )}
      {props.type === "icon" && (
        <FontAwesomeIcon className="ml-3 mr-2" icon={props.data} />
      )}
      <Link className="text-sm tracking-wider" to={props.uri}>
        {props.title}
      </Link>
    </li>
  );
}

export default MenuItem;
