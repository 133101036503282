import { capitalizeFirstLetter } from "../../utils/general";
import TimePassed from "../TimePassed";

function FeedItem({ feedType, data }) {
  // https://maketintsandshades.com/
  const circleBackground = {
    discord: "#bcc1fa",
    twitter: "#a5d9fa",
    youtube: "#ff9999",
    blog: "#c5da83",
  };

  const circleBorder = {
    youtube: "2px solid #ff3333",
    discord: "2px solid #7984f5",
    twitter: "2px solid #4ab4f5",
    blog: "2px solid #9FC131",
  };

  return (
    <div className="p-2 space-y-1">
      <div className="flex justify-between text-sm text-gray-400 font-semibold">
        <div className="flex items-center">
          <span
            className="inline-flex h-3 w-3 rounded-full mr-1 border-2"
            style={{
              backgroundColor: circleBackground[data.platform],
              border: circleBorder[data.platform],
            }}
          ></span>
          {feedType === "youtube" && capitalizeFirstLetter(data.video_user)}
          {feedType === "discord" &&
            capitalizeFirstLetter(data.message_project)}
          {feedType === "twitter" && capitalizeFirstLetter(data.space_user)}
          {feedType === "blog" && capitalizeFirstLetter(data.article_publisher)}
        </div>
        <div>
          <TimePassed timeStamp={data.platform_date} />
        </div>
      </div>
      <div id="title" className="text-md font-bold truncate">
        {feedType === "youtube" && capitalizeFirstLetter(data.video_title)}
        {feedType === "discord" &&
          capitalizeFirstLetter(data.message_project) +
            " discord accouncement."}
        {feedType === "twitter" &&
          capitalizeFirstLetter(data.space_user) + " started a Twitter space."}
        {feedType === "blog" && capitalizeFirstLetter(data.article_title)}
      </div>

      <div id="description" className="text-sm truncate">
        {feedType === "youtube" && data.video_description}
        {feedType === "discord" && data.message_text}
        {feedType === "twitter" && data.space_title}
        {feedType === "blog" && data.article_text}
      </div>
    </div>
  );
}

export default FeedItem;
