import { useEffect, useState } from "react";

function useSortMessages(socketMessages, databaseMessages, filtering) {
  const [sortedItems, setSortedItems] = useState([]);

  useEffect(() => {
    if (socketMessages && databaseMessages) {
      setSortedItems([]);
      const mergedMessages = [...databaseMessages, ...socketMessages];
      const sortedMessages = mergedMessages.sort(
        (a, b) => b.platform_date - a.platform_date
      );
      const platformSpecificMessages = sortedMessages.filter(
        (item) => item.platform === filtering
      );

      if (filtering === "all") {
        setSortedItems(sortedMessages);
      } else {
        setSortedItems(platformSpecificMessages);
      }
    }
  }, [socketMessages, databaseMessages, filtering]);

  return sortedItems;
}

export default useSortMessages;
