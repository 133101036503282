import useFetchAccountData from "../../../hooks/useFetchAddressData";
import { numberOfDecimals } from "../../../utils/general";
import TokenSkeleton from "./Skeleton";

function Tokens({ currentPrices, stakeAddress }) {
  function generateRandomColor() {
    const r = Math.floor(Math.random() * 256);
    const g = Math.floor(Math.random() * 256);
    const b = Math.floor(Math.random() * 256);
    return `rgb(${r},${g},${b})`;
  }

  function calculatePortfolioTotal(token) {
    return (token.price * token.quantity) / Math.pow(10, token.decimals);
  }

  function calculatePortfolioAllocation(token, portfolioValue) {
    return (
      ((token.price * token.quantity) /
        Math.pow(10, token.decimals) /
        portfolioValue) *
      100
    );
  }

  const tokenData = useFetchAccountData(
    `/api/portfolio/account/token/${stakeAddress}`
  );

  const portfolioValue = tokenData?.data.reduce((totalValue, token) => {
    return (
      totalValue + (token.price * token.quantity) / Math.pow(10, token.decimals)
    );
  }, 0);

  const tokens = tokenData?.data.map((token) => {
    return {
      ...token,
      total: calculatePortfolioTotal(token),
      allocation: calculatePortfolioAllocation(token, portfolioValue),
      color: generateRandomColor(),
    };
  });

  tokens.sort((a, b) => b.allocation - a.allocation);

  return (
    <>
      {tokenData.dataLoaded ? (
        <>
          {/* stats */}
          <div className="flex-col lg:flex-row space-y-2 lg:space-y-0 lg:space-x-2 lg:flex mb-10">
            <div className="w-full lg:w-1/2 p-10 bg-white drop-shadow-sm rounded-lg border">
              <p className="leading-relaxed text-xl font-thin mb-5 uppercase">
                <span className="bg-green-500 px-1 mr-1 h-9"></span>Balance
              </p>
              <p className="text-5xl font-thin">
                {numberOfDecimals(portfolioValue)} ADA
              </p>
              <p className="text-xl font-thin text-gray-400 mt-2">
                {numberOfDecimals(portfolioValue * currentPrices.ada)} USD
              </p>
            </div>
            <div className="w-full lg:w-1/4 p-10  bg-white drop-shadow-sm rounded-lg border">
              <p className="leading-relaxed text-xl font-thin mb-5 uppercase">
                <span className="bg-blue-500 px-1 mr-1 h-9"></span>ADA Price
              </p>
              <p className="text-5xl font-thin">
                {numberOfDecimals(currentPrices.ada)} USD
              </p>
              <p className="text-xl font-thin text-gray-400 mt-2">1 ADA</p>
            </div>
            <div className="w-full lg:w-1/4 p-10  bg-white drop-shadow-sm rounded-lg border">
              <p className="leading-relaxed text-xl font-thin mb-5 uppercase">
                <span className="bg-yellow-400 px-1 mr-1 h-9"></span>DJED Price
              </p>
              <p className="text-5xl font-thin">
                {numberOfDecimals(currentPrices.djed)} USD
              </p>
              <p className="text-xl font-thin text-gray-400 mt-2">
                {numberOfDecimals(currentPrices.djed / currentPrices.ada)} ADA
              </p>
            </div>
          </div>

          {/* portfolio */}
          <div className="p-10 mb-10 bg-white drop-shadow-sm rounded-lg border">
            {/* bar */}
            <div className="flex">
              {tokens &&
                tokens.map((token, index) => (
                  <div
                    key={token.policy_id}
                    className={
                      index === 0
                        ? "h-10 first:rounded-l-lg"
                        : index === tokens.length - 1
                        ? "h-10 last:rounded-r-lg"
                        : "h-10"
                    }
                    style={{
                      backgroundColor: token.color,
                      width: `${token.allocation}%`,
                      minWidth: "1px",
                    }}
                  >
                    <span className="sr-only">{`${token.allocation}%`}</span>
                  </div>
                ))}
            </div>

            {/* titles */}
            <div className="flex flex-wrap gap-7 mt-9">
              {tokens &&
                tokens.map((token) => (
                  <div key={token.asset_name} className="flex items-center">
                    <span
                      className="h-3 w-3 rounded-full mr-1"
                      style={{
                        backgroundColor: token.color,
                        border: token.color,
                      }}
                    ></span>
                    <p>
                      {token.ticker} {numberOfDecimals(token.allocation)}%
                    </p>
                  </div>
                ))}
            </div>
          </div>

          {/* table */}
          <div className="p-10 drop-shadow-sm bg-white rounded-lg border">
            <div className="flex self-center justify-center w-full overflow-auto">
              <table className="w-full overflow-auto text-md text-left table-auto">
                <thead>
                  <tr>
                    <th className="px-8 py-6">Token</th>
                    <th className="px-8 py-6">Ticker</th>
                    <th className="px-8 py-6">Price</th>
                    <th className="px-8 py-6">Supply</th>
                    <th className="px-8 py-6">Holdings</th>
                    <th className="px-8 py-6">Value</th>
                  </tr>
                </thead>
                <tbody>
                  {tokens &&
                    tokens.map((item) => (
                      <tr
                        key={item.asset_name}
                        className="border-t hover:bg-slate-100"
                      >
                        <td className="px-8 py-6">{item.name}</td>
                        <td className="px-8 py-6">{item.ticker}</td>
                        <td className="px-8 py-6">
                          {numberOfDecimals(item.price)} ₳
                        </td>
                        <td className="px-8 py-6">
                          {numberOfDecimals(
                            item.supply / Math.pow(10, item.decimals)
                          )}
                        </td>
                        <td className="px-8 py-6">
                          {numberOfDecimals(
                            item.quantity / Math.pow(10, item.decimals)
                          )}
                        </td>
                        <td className="px-8 py-6">
                          {numberOfDecimals(
                            (item.price * item.quantity) /
                              Math.pow(10, item.decimals)
                          )}{" "}
                          ₳
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </>
      ) : (
        <TokenSkeleton />
      )}
    </>
  );
}

export default Tokens;
